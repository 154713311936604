<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="日报日期">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="姓名|电话"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" @click="doExport()">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="reportDate" slot-scope="scope">
          <el-button type="text" @click="$site.view_virusdailyreport_detail(scope.row.reportId)">{{ scope.row.reportDate }}</el-button>
        </template>
        <template slot="frksFlag" slot-scope="scope">
          <span v-if="scope.row.frksFlag == 0">无</span>
          <span v-else-if="scope.row.frksFlag == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="workFlag" slot-scope="scope">
          <span v-if="scope.row.workFlag == 0">未上班</span>
          <span v-else-if="scope.row.workFlag == 1">上班</span>
        </template>
        <template slot="temp" slot-scope="scope">
          <span v-if="!scope.row.temp" class="text-red">未上报</span>
          <span v-if="scope.row.temp < 37.2">{{ scope.row.temp }}℃</span>
          <span v-else class="text-red text-bold">{{ scope.row.temp }}℃</span>
        </template>
        <template slot="isExist" slot-scope="scope">
          <span v-if="scope.row.isExist == 0">无</span>
          <span v-else-if="scope.row.isExist == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isDangerArea" slot-scope="scope">
          <span v-if="scope.row.isDangerArea == 0">无</span>
          <span v-else-if="scope.row.isDangerArea == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isHS48" slot-scope="scope">
          <span v-if="scope.row.isHS48 == 0" class="text-red text-bold">无</span>
          <span v-else-if="scope.row.isHS48 == -1">不需要</span>
          <span v-else-if="scope.row.isHS48 == 1">有</span>
        </template>
        <template slot="skmColor" slot-scope="scope">
          <div class="flex align-center justify-center">
            <span v-if="scope.row.skmColor === 'G'" class="bg-green opbk" style="padding: 10px 30px 10px 30px"></span>
            <span v-else-if="scope.row.skmColor === 'Y'" class="bg-yellow opbk" style="padding: 10px 30px 10px 30px"></span>
            <span v-else-if="scope.row.skmColor === 'R'" class="bg-red opbk" style="padding: 10px 30px 10px 30px"></span>
          </div>
        </template>
        <template slot="xcmColor" slot-scope="scope">
          <div class="flex align-center justify-center">
            <span v-if="scope.row.xcmColor === 'G'" class="bg-green opbk" style="padding: 10px 30px 10px 30px"></span>
            <span v-else-if="scope.row.xcmColor === 'Y'" class="bg-yellow opbk" style="padding: 10px 30px 10px 30px"></span>
            <span v-else-if="scope.row.xcmColor === 'R'" class="bg-red opbk" style="padding: 10px 30px 10px 30px"></span>
          </div>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'MyCarBorrowList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '日期', slot: 'reportDate' },
          { label: '姓名', prop: 'userName' },
          { label: '部门', prop: 'departName' },
          { label: '是否上班', slot: 'workFlag' },
          { label: '发热咳嗽', slot: 'frksFlag' },
          { label: '出入宁', slot: 'isExist' },
          { label: '中高风险区', slot: 'isDangerArea' },
          { label: '体温', slot: 'temp' },
          { label: '苏康码', slot: 'skmColor' },
          { label: '行程码', slot: 'xcmColor' },
          { label: '48小时核酸', slot: 'isHS48' }
        ],
        operation: {
          width: '160',
          buttons: [{ type: 'primary', icon: 'el-icon-printer', label: '详细信息', click: this.detail }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/virusdailyreport/list', ['enterpriseCode', this.condition])
    },
    detail(item) {
      this.$site.view_virusdailyreport_detail(item.reportId)
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/virusdailyreport/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url, download: '防疫日报导出' + new Date().valueOf() + '.xlsx' } }, '点击下载'))
      })
    }
  }
}
</script>

<style scoped>
.opbk{
  opacity: 0.6;
  border-radius: 5px;
}
</style>